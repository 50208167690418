import React, { useEffect, useState } from 'react';
import { DouayRheims, DouayRheimsBibleBook } from './features/DouayRheims/DouayRheims';
import { PrivacyPolicy } from './features/PrivacyPolicy/PrivacyPolicy';
import './App.css';
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { Container, Navbar, Offcanvas } from 'react-bootstrap';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-9N598JERH5");


function App() {
    const APP_TITLE = "Omni Verbo";

    const location = useLocation();
    const [page, setPage] = useState();

    useEffect(() => {
        const newPage = `${location.pathname}${location.search}`;
        if (newPage === "/" || (location.search && newPage !== page)) {
          ReactGA.send({ hitType: "pageview", "page": newPage });
          setPage(newPage);
        }
    }, [ location, page ]);

    return (
    <div className="App">
      <Navbar variant="dark" expand={ false } className="bg-primary bg-gradient">
        <Container>
          <Navbar.Brand href="/" className='text-uppercase'>{ APP_TITLE }</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Offcanvas placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                Navigation
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p>
                Thank you for using our Catholic Bible app, which is still under development.  If you come across a glitch or have a feature request, please contact us by sending an email to this address: <a href="mailto:contact@omniverbo.com?subject=Privacy Policy Question">contact@omniverbo.com</a>.
              </p>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <main>
        <Routes>
          <Route path="/" element={ <DouayRheims /> } />
          <Route path="/:bibleBookName" element={<DouayRheimsBibleBook />} />
          <Route path="/:bibleBookName/:chapter" element={<DouayRheimsBibleBook />} />
          <Route path="/bible" element={ <DouayRheims /> } />
          <Route path="/bible/:bibleBookName" element={ <DouayRheimsBibleBook /> } />
          <Route path="/bible/:bibleBookName/:chapter" element={ <DouayRheimsBibleBook /> } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
    </div>
    );
}

export default App;
