import React from "react";
import CoverImage from "./loading-image.webp";
import { Col, Container, Row, Image, Spinner } from "react-bootstrap";


export const LoadingScreen = () => {
    return (
        <div className="LoadingScreen">
            <Container>
                <Row>
                    <Col className="col-1 col-md-3"></Col>
                    <Col className="col-10 col-md-6">
                        <Image src={ CoverImage } width="100%" height="auto" />
                        <div className="py-5">
                            <Spinner />
                        </div>
                    </Col>
                    <Col className="col-1 col-md-3"></Col>
                </Row>
            </Container>
        </div>
    );
}