import React, { useState, useEffect } from "react";
import { useGetBibleBookByNameQuery } from "./douayRheimsAPI";
import { Button, ButtonGroup, Row, Container, Col } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { bibleBookNames, otBibleBookNames, ntBibleBookNames } from "./bibleBooks";
import { LoadingScreen } from "../LoadingScreen/LoadingScreen";

export const DouayRheimsBookSummary = ({ bibleBook }) => {
    return (
        <div className="DouayRheimsBookSummary text-start mt-3">
            <h5><u>Book Summary</u></h5>
            <p className="text-left">{ bibleBook.description }</p>
        </div>
    );
}

export const DouayRheimsChapterMenu = ({ bibleBook, showSubheading }) => {
    const navigate = useNavigate();
    const { chapter } = useParams();

    const handleChapterClick = (chapterNumber) => {
        const shortName = bibleBookNames[bibleBook.name];
        navigate(`/bible/${shortName}/${chapterNumber}`);
    }

    return (
        <div className="DouayRheimsChapters text-start">
            {
                showSubheading ?
                <h5><u>Chapters</u></h5> :
                null
            }
            <div style={{overflowY: "auto", maxHeight: "200px"}}>
            {
                Object.keys(bibleBook.chapters).map(currentChapter =>
                    <Button key={ currentChapter } variant={currentChapter === chapter ? "primary" : "secondary"} className="m-1" onClick={ () => handleChapterClick(currentChapter) }>
                        { currentChapter }
                    </Button>
                )
            }
            </div>
        </div>
    );
}

export const DouayRheimsTestament = ({ testamentName, description, bibleBookNames }) => {
    const navigate = useNavigate();

    return (
        <>
            <h3>{ testamentName }</h3>
            <p>{ description }</p>
            <div>
            {
                Object.keys(bibleBookNames).map(bibleBookName =>
                    <Button key={ bibleBookName } className="m-1" variant="secondary" onClick={ () => navigate(`/bible/${ bibleBookNames[bibleBookName] }`) }>{ bibleBookName }</Button>
                )
            }
            </div>
        </>
    );
}

export const DouayRheims = () => {
    return (
        <div className="DouayRheims py-4">
            <Container>
                <Row className="py-3">
                    <Col>
                        <h3 className="text-uppercase display-1">The Holy Bible</h3>
                        <p>TRANSLATED FROM THE LATIN VULGATE</p>
                        <p>Diligently compared with the Hebrew, Greek, and other editions in divers languages.</p>
                        <p>The whole revised and diligently compared with the Latin Vulgate by the Servant of God Bishop Richard Challoner, A.D. 1749-1752.</p>
                    </Col>
                </Row>
                <Row className="py-3">
                    <DouayRheimsTestament
                        testamentName={ "Old Testament" }
                        description={ "First published by the English College of Douay, A.D. 1609." }
                        bibleBookNames={ otBibleBookNames }
                    />
                </Row>
                <Row className="py-3">
                    <DouayRheimsTestament
                        testamentName={ "New Testament" }
                        description={ "First published by the English College of Rheims, A.D. 1582." }
                        bibleBookNames={ ntBibleBookNames }
                    />
                </Row>
            </Container>
        </div>
    );
}

export const DouayRheimsChapter = ({ bibleBook, chapter }) => {
    const [search, setSearch] = useSearchParams();
    const verse_str = search.get("vv");
    // const [highlightedChapter, setHighlightedChapter] = useState('0');
    const [highlightedVerses, setHighlightedVerses] = useState(new Set());

    useEffect(() => {
        if (verse_str) {
            const verse_str_parts = verse_str.split(",")
            for (let index = 0; index < verse_str_parts.length; index++) {
                const verse = verse_str_parts[index];
                if (verse.includes("-")) {
                    const range_params = verse.split("-");
                    if (range_params[0] < range_params[1]) {
                        const minVerse = parseInt(range_params[0]);
                        const maxVerse = parseInt(range_params[1]);
                        for (let index = minVerse; index <= maxVerse; index++) {
                            setHighlightedVerses(prev => new Set(prev.add(index.toString())));
                        }
                    }
                } else {
                    setHighlightedVerses(prev => new Set(prev.add(verse)));
                }
            }
            // setHighlightedChapter(chapter);
        } else {
            setHighlightedVerses(new Set());
        }
        /*
        if (highlightedChapter > '0' && highlightedChapter !== chapter) {
            setHighlightedChapter('0');
            setHighlightedVerses(new Set());
        }
        */
    }, [verse_str]);

    /*
    useEffect(() => {
        if (highlightedVerses.size > 0) {
            const minHighlightedVerse = Math.min(...highlightedVerses);
            const anchor = document.querySelector(`#${ bibleBookNames[bibleBook.name] }-${ chapter }-${ minHighlightedVerse }`);
            if (anchor) {
                anchor.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'nearest'
                    // block: 'center'
                });
            }
        }
    }, [ ])
    */

    return (
        <div className="DouayRheimsChapter text-start">
            <h5><u>Chapter { chapter }</u></h5>
            {
                "description" in bibleBook.chapters[chapter] ?
                Object.keys(bibleBook.chapters[chapter].description).map(key =>
                    <p key={ key } className="text-muted">{ bibleBook.chapters[chapter].description[key] }</p>
                ) :
                null
            }
            {
                Object.keys(bibleBook.chapters[chapter].verses).map(verseNumber =>
                    <div key={ verseNumber }>
                        {
                            highlightedVerses.has(verseNumber) ?
                            <div key={ verseNumber } id={`${ bibleBookNames[bibleBook.name] }-${ chapter }-${ verseNumber }`}><mark>&nbsp;&nbsp;{ verseNumber }&nbsp;&nbsp;{ bibleBook.chapters[chapter].verses[verseNumber].text }</mark></div> :
                            <div key={ verseNumber }>&nbsp;&nbsp;{ verseNumber }&nbsp;&nbsp;{ bibleBook.chapters[chapter].verses[verseNumber].text }</div>
                        }
                        {
                            "footnotes" in bibleBook.chapters[chapter].verses[verseNumber] ?
                            <div className="bg-light p-3">{ bibleBook.chapters[chapter].verses[verseNumber].footnotes[0] }</div> :
                            null
                        }
                    </div>
                )
            }
        </div>
    );
}

export const DouayRheimsBibleBook = () => {
    const navigate = useNavigate();
    const { bibleBookName, chapter } = useParams();
    const { data: bibleBook, error, isLoading } = useGetBibleBookByNameQuery(bibleBookName);

    return (
        <div className="DouayRheimsBibleBook">
            <Container className="pt-3 pb-5 fs-4">
            {
                error ? (
                    <>Error loading Bible text.</>
                ) : isLoading ? (
                    <LoadingScreen />
                ) : bibleBook ? (
                    <div>
                        <Row className="mb-3">
                            <div>
                                <ButtonGroup>
                                    <Button variant="outline-secondary" onClick={ () => navigate("/bible") }>Table of Contents</Button>
                                    <Button variant="outline-secondary" onClick={ () => navigate(`/bible/${ bibleBookNames[bibleBook.name] }`) }>Book Summary</Button>
                                </ButtonGroup>
                            </div>
                        </Row>
                        <Row className="pb-3">
                            <h2>{ bibleBook.full_name }</h2>
                        </Row>
                        {
                            chapter ? (
                                <>
                                    <Row>
                                        <DouayRheimsChapterMenu bibleBook={ bibleBook } />
                                    </Row>
                                    <Row className="py-3">
                                        <DouayRheimsChapter bibleBook={ bibleBook } chapter={ chapter } />
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <DouayRheimsChapterMenu bibleBook={ bibleBook } showSubheading={ true } />
                                    </Row>
                                    <Row>
                                        <DouayRheimsBookSummary bibleBook={ bibleBook } />
                                    </Row>
                                </>
                            )
                        }
                    </div>
                ) : null
            }
            </Container>
        </div>
    );
}