export const bibleBookNames = {
    "Genesis": "gen",
    "Exodus": "exo",
    "Leviticus": "lev",
    "Numbers": "num",
    "Deuteronomy": "deu",
    "Josue": "jos",
    "Judges": "jdg",
    "Ruth": "rut",
    "1 Kings": "1ki",
    "2 Kings": "2ki",
    "3 Kings": "3ki",
    "4 Kings": "4ki",
    "1 Paralipomenon": "1pa",
    "2 Paralipomenon": "2pa",
    "1 Esdras": "1es",
    "2 Esdras": "2es",
    "Tobias": "tob",
    "Judith": "jdt",
    "Esther": "est",
    "Job": "job",
    "Psalms": "psa",
    "Proverbs": "pro",
    "Ecclesiastes": "ecc",
    "Canticle of Canticles": "can",
    "Wisdom": "wis",
    "Ecclesiasticus": "sir",
    "Isaias": "isa",
    "Jeremias": "jer",
    "Lamentations": "lam",
    "Baruch": "bar",
    "Ezechiel": "eze",
    "Daniel": "dan",
    "Osee": "ose",
    "Joel": "joe",
    "Amos": "amo",
    "Abdias": "abd",
    "Jonas": "jon",
    "Micheas": "mic",
    "Nahum": "nah",
    "Habacuc": "hab",
    "Sophonias": "sop",
    "Aggeus": "agg",
    "Zacharias": "zac",
    "Malachias": "mal",
    "1 Machabees": "1ma",
    "2 Machabees": "2ma",
    "Matthew": "mat",
    "Mark": "mrk",
    "Luke": "luk",
    "John": "jhn",
    "Acts": "act",
    "Romans": "rom",
    "1 Corinthians": "1co",
    "2 Corinthians": "2co",
    "Galatians": "gal",
    "Ephesians": "eph",
    "Philippians": "phi",
    "Colossians": "col",
    "1 Thessalonians": "1th",
    "2 Thessalonians": "2th",
    "1 Timothy": "1ti",
    "2 Timothy": "2ti",
    "Titus": "tit",
    "Philemon": "phm",
    "Hebrews": "heb",
    "James": "jam",
    "1 Peter": "1pe",
    "2 Peter": "2pe",
    "1 John": "1jn",
    "2 John": "2jn",
    "3 John": "3jn",
    "Jude": "jud",
    "Apocalypse": "apo"
}

export const otBibleBookNames = {
    "Genesis": "gen",
    "Exodus": "exo",
    "Leviticus": "lev",
    "Numbers": "num",
    "Deuteronomy": "deu",
    "Josue": "jos",
    "Judges": "jdg",
    "Ruth": "rut",
    "1 Kings": "1ki",
    "2 Kings": "2ki",
    "3 Kings": "3ki",
    "4 Kings": "4ki",
    "1 Paralipomenon": "1pa",
    "2 Paralipomenon": "2pa",
    "1 Esdras": "1es",
    "2 Esdras": "2es",
    "Tobias": "tob",
    "Judith": "jdt",
    "Esther": "est",
    "Job": "job",
    "Psalms": "psa",
    "Proverbs": "pro",
    "Ecclesiastes": "ecc",
    "Canticle of Canticles": "can",
    "Wisdom": "wis",
    "Ecclesiasticus": "sir",
    "Isaias": "isa",
    "Jeremias": "jer",
    "Lamentations": "lam",
    "Baruch": "bar",
    "Ezechiel": "eze",
    "Daniel": "dan",
    "Osee": "ose",
    "Joel": "joe",
    "Amos": "amo",
    "Abdias": "abd",
    "Jonas": "jon",
    "Micheas": "mic",
    "Nahum": "nah",
    "Habacuc": "hab",
    "Sophonias": "sop",
    "Aggeus": "agg",
    "Zacharias": "zac",
    "Malachias": "mal",
    "1 Machabees": "1ma",
    "2 Machabees": "2ma",
}

export const ntBibleBookNames = {
    "Matthew": "mat",
    "Mark": "mrk",
    "Luke": "luk",
    "John": "jhn",
    "Acts": "act",
    "Romans": "rom",
    "1 Corinthians": "1co",
    "2 Corinthians": "2co",
    "Galatians": "gal",
    "Ephesians": "eph",
    "Philippians": "phi",
    "Colossians": "col",
    "1 Thessalonians": "1th",
    "2 Thessalonians": "2th",
    "1 Timothy": "1ti",
    "2 Timothy": "2ti",
    "Titus": "tit",
    "Philemon": "phm",
    "Hebrews": "heb",
    "James": "jam",
    "1 Peter": "1pe",
    "2 Peter": "2pe",
    "1 John": "1jn",
    "2 John": "2jn",
    "3 John": "3jn",
    "Jude": "jud",
    "Apocalypse": "apo"
}