import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const douayRheimsAPI = createApi({
    reducerPath: "douayRheimsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.omniverbo.com/",
        mode: "cors",
        prepareHeaders: (headers) => {
            headers.set("X-Api-Key", "CFjUTKAvya3OMmMkjJQly7sjnNMNfmma54oS5ssj");
            headers.set("Content-Type", "application/json");
        }
    }),
    endpoints: (builder) => ({
        getBibleBookByName: builder.query({
            query: (name) => `bible/drb/${name}`,
        })
    }),
})

export const { useGetBibleBookByNameQuery } = douayRheimsAPI;
