import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { douayRheimsAPI } from '../features/DouayRheims/douayRheimsAPI';


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [douayRheimsAPI.reducerPath]: douayRheimsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(douayRheimsAPI.middleware),
});
